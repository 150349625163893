.chromeSection {
  align-items: center;
  background: url("../../assets/images/bg-otp.svg") no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  .innerContainer {
    margin: auto;
    position: absolute;
    text-align: center;
    top: 15%;
    width: 85%;
    @media screen and (min-width: 1200px) { 
      width: 70%;
    }

    @media screen and (min-width: 1400px) { 
      width: 59%;
    }

    @media screen and (min-width: 1900px) { 
      width: 44%;
    }

    h1 {
      color: #14142a;
      font-size: 34px;
      font-weight: 600;
      line-height: 41px;
    }

    p {
      color: #4e4b66;
      font-size: 18px;
      font-weight: normal;
      line-height: 22px;
      padding: 10px 40px;
      text-align: center;
    }

    .extensionImg {
      height: 480px;
      min-width: 100%;
    }

    .extensionButton {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      margin: 15px;
      .chromeButton {
        background: #2934d0;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(44, 90, 246, 0.23);
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        height: 50px;
        line-height: 19px;
        text-align: center;
        width: 325px;
      }

      .chromeBtn {
        padding-right: 10px;
      }
    }
  }
}
  