@import '../../styles/variables.scss';
@import '../../styles//mixins.scss';

.ConfirmationCard {
  position: absolute;
  top: 35%;
  width: 94%;
}

.ConfirmationCardOuter {
  margin: auto;
  text-align: center;
  width: 500px;
}

.cardInner {
  background: $whiteColor;
  border-radius: 7px;
  box-shadow: 0 1px 56px rgba(20, 20, 42, 0.14);
  padding: 10%;
  h4 {
    color: $primaryFontColor;
    font-size: calculateEm(24px);
    line-height: 29px;
  }

  p {
    color: $secondaryFontColor;
    font-size: calculateEm(16px);
    line-height: 19px;
    padding: 1% 10%;
  }

  .buttonRow {
    display: flex;
    margin-top: 3%;
    width: 100%;
  }
}

.formSection {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  justify-content: center;
  .inputSection {
    width: 50%;
    background: #f6f6f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .nurtureBoxImg {
      left: 20px;
      position: fixed;
      top: 20px;
    }
    form {
      width: 100%;
      .formParent {
        padding: 10%;
        width: 500px;
        margin: auto;
        @media screen and (max-width: 1100px) {
          width: 400px;
        }
        h4 {
          color: $primaryFontColor;
          font-size: calculateEm(24px);
          line-height: 29px;
        }

        .subHeading {
          color: $secondaryFontColor;
          font-size: calculateEm(16px);
          line-height: 19px;
          padding-top: 1%;
        }

        label {
          color: $secondaryFontColor;
          font-weight: 500;
          line-height: 19px;
          margin: 10px 0 7px 0;
        }

        input::placeholder {
          color: $placeholder !important;
          font-size: calculateEm(16px) !important;
          line-height: 19px !important;
        }

        input {
          background: $whiteColor !important;
          border: 1px solid #d9dbe9;
          border-radius: 10px;
          height: 50px;
        }
      }
    }
  }
}

.loginNAme {
  display: flex;
  .firstName {
    margin: 0 2% 0 0;
    width: 50%;
  }

  .lastName {
    margin: 0 0 0 2%;
    margin-top: 37px;
    width: 50%;
  }
}

.formButton {
  margin: 5% 0 0 0;
  text-align: center;
  .disclaimer {
    color: $placeholder;
    font-size: calculateEm(16px);
    line-height: 22px;
    padding: 4% 10% 0 10% !important;
    text-align: center;
  }

  .submitBtn {
    background: $primaryColor;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(44, 90, 246, 0.23);
    color: $whiteColor;
    font-size: calculateEm(16px);
    height: 50px;
    margin: auto;
    min-width: 280px;
    padding: 4% 8%;
  }
}

.otpFormSection {
  display: flex;
  margin: 0;
  height: 100%;
  align-items: stretch;
  width: 100%;
  .inputSection {
    width: 50%;
    background: #f6f6f6;
    align-items: center;
    display: flex;
    justify-content: center;
    .nurtureBoxImg {
      left: 20px;
      position: fixed;
      top: 20px;
    }
    .formParent {
      background: #f6f6f6;
      border-radius: 7px;
      box-sizing: border-box;
      margin: auto;
      padding: 10%;
      position: relative;
      width: 500px;
      h4 {
        color: $primaryFontColor;
        font-size: calculateEm(24px);
        line-height: 29px;
      }

      .subHeading {
        color: $secondaryFontColor;
        font-size: calculateEm(16px);
        line-height: 19px;
        padding: 1% 0 3% 0;
      }

      label {
        font-weight: normal !important;
        line-height: 19px !important;
      }

      input::placeholder {
        color: $placeholder !important;
        font-size: calculateEm(16px) !important;
        line-height: 19px !important;
      }

      input {
        background: $whiteColor !important;
        border: 1px solid #d9dbe9;
        border-radius: 10px;
        height: 50px;
        z-index: 0;
      }
      .phoneInputInput {
        padding-left: 10px;
        border: none;
      }
    }
  }
}

.otpFormButton {
  margin: 5% 0 0 0;
  text-align: center;
  p {
    color: $placeholder;
    display: inline-flex;
    font-size: calculateEm(16px);
    line-height: 22px;
    padding: 4% 10% 0 10% !important;
    text-align: center;
    .resend {
      color: $primaryColor;
      padding: 0 0 0 5px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .submitBtn {
    background: $primaryColor;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(44, 90, 246, 0.23);
    color: $whiteColor;
    font-size: calculateEm(16px);
    margin: auto;
    padding: 4% 8%;
    height: 45px;
  }
}

.numberField {
  position: relative;

  .phoneNumberInput {
    border: 1px solid #d9dbe9 !important;
    border-radius: 4px;
    padding-left: 10px;
    z-index: 1;
    background: $whiteColor;
    border-radius: 10px;

    input {
      border: 0 !important;
      padding-left: 10px;
      box-shadow: none;
      transition: box-shadow 0.1s linear;
    }

    &:focus {
      box-shadow: 0 0 1pt 1pt $primaryColor !important;
      outline: none !important;
    }

    input:focus {
      box-shadow: 0 0 1pt 1pt $primaryColor !important;
      outline: none !important;
    }
    input:disabled {
      cursor: not-allowed;
      opacity: 0.85;
    }
    .phoneInputInput {
      border: none;
    }
  }

  .editButton {
    color: $primaryColor;
    cursor: pointer;
    font-size: calculateEm(16px);
    position: absolute;
    top: 55px;
    right: 15px;

    &.cancelEdit {
      color: #f44336;
    }
  }

  label {
    color: $secondaryFontColor;
    display: block;
    padding: 7px 0 4px 0;
    font-size: 12px;
  }
}

.otpError {
  color: #d32e2a;
  font-size: 16px;
  font-style: italic;
  line-height: 19px;
}

.tryAgainBtn {
  background: $primaryColor;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(44, 90, 246, 0.23);
  color: $whiteColor;
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  line-height: 19px;
  margin: 0 3%;
  text-align: center;
  width: 48%;
}

.closeBtn {
  background: $whiteColor;
  border: 0;
  box-shadow: none;
  color: $secondaryFontColor;
  font-size: 16px;
  height: 48px;
  line-height: 19px;
  margin: 0 3%;
  text-align: center;
  width: 48%;
}

.otpTimer {
  color: $primaryColor;
}

.rightSection {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  // @media screen and (max-height: 800px) {
  //   min-height: 800px;
  // }
  // @media screen and (min-height:801px) {
  //   height: 100vh;
  // }

  .title {
    color: $primaryFontColor;
    font-size: 24px;
    line-height: 29px;
  }

  .description {
    color: $secondaryFontColor;
    font-size: 16px;
    line-height: 19px;
    padding: 1% 0 7% 0;
  }

  .dataSection {
    width: 100%;
    display: flex;
    margin-bottom: 5%;
    .image {
      width: 50%;
      align-items: center;
      display: flex;
      flex-direction: column;
      img {
        display: block;
        max-width: 100%;
      }
      .adjustHeight {
        @media screen and (max-height: 800px) {
          height: 210px !important;
        }
      }
      .subImage {
        @media screen and (max-height: 800px) {
          height: 50px !important;
        }
      }
      .lemlistImage {
        @media screen and (max-height: 800px) {
          height: 120px !important;
        }
      }
    }
    .flexEnd {
      align-items: flex-end !important;
      img {
        height: 350px !important;
        @media screen and (max-height: 800px) {
          height: 270px !important;
        }
      }
    }

    .imageDescription {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      h4 {
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        color: $primaryFontColor;
        padding: 0 3%;
      }
    }
    .textAlign {
      align-items: end !important;
    }
  }
}

.footerSection {
  text-align: center;
  margin: 2%;
  border-top: 1px solid $secondaryFontColor;
  width: 92%;
  .footerTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: $primaryFontColor;
    padding: 5% 2% 0;
  }
  .footerDescription {
    font-size: 16px;
    line-height: 25px;
    color: $secondaryFontColor;
    padding: 1% 10%;
  }
  .companyImages {
    margin-top: 5%;
    img {
      margin: 0 1%;
      @media screen and (max-width: 1400px) {
        margin: 0 0.5%;
      }
    }
  }
}
