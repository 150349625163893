@font-face {
  font-family: 'Inter-regular', sans-serif;
  src: url('./assets/fonts/Inter-Regular.ttf') format("truetype");
  font-display: swap;
}
html {
  scroll-behavior: smooth;
  height: 100%;
}
body {
  font-family: 'Inter-regular', sans-serif;
  -webkit-text-stroke: 0.1px;
  letter-spacing: 0.2px;
  background-color: #FBFBFF;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  height: 100%;
}

a,p, a:visited, a:active, a:enabled {
  text-decoration: none;
}

p,h1,h2,h3,h4,h5,h6 {
  margin: 0
}

.MuiBackdrop-root {
  left: 65px !important;
}

body::-webkit-scrollbar {
  width: 6px;
}

div::-webkit-scrollbar,
section::-webkit-scrollbar {
  width: 5px;
}
div::-webkit-scrollbar-track,
section::-webkit-scrollbar-track {
  background-color: transparent;
}
div::-webkit-scrollbar-thumb,
section::-webkit-scrollbar-thumb {
  background-color: #6E7191;
  border-radius: 10px;
}
div::-webkit-scrollbar-thumb:hover,
section::-webkit-scrollbar-thumb:hover {
  background-color: #585a74;
}

/* @media screen and (min-width: 1201px) {
  body {
    font-size: 16px;
  }
}

@media screen and (max-width: 1200px) {
  body {
    font-size: 15px;
  }
}

@media screen and (max-width: 425px) {
  body {
    font-size: 14px;
  }
  .MuiBackdrop-root {
    left: 0px !important;
  }
} */
