@import '../../../styles/variables.scss';
@import '../../../styles/utils.scss';

.fileUploadBox {

  .input {
    display: none;
  }

  .label {
    align-items: center;
    color: $secondaryFontColor;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    justify-content: space-between;
    margin: 10px 0 5px;

    .infoIcon {
      margin: 0 0 -2px 3px;
      width: 13px;
    }

    .optionalLabel {
      color: #6e7191;
      float: right;
    }
  }

  .uploadSection {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .filePathContainer {
      border: 1px solid $primaryBorder;
      border-radius: 3px;
      box-sizing: border-box;
      color: $placeholder; 
      height: 34px;
      padding: 0 10px;
      width: 75%;
    }

    .uploadButton {
      font-weight: normal;
      width: 23%;

      &.fullWidth {
        width: 100%;
      }
    }
  }
}
