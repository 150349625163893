@import "../../../styles/variables.scss";

.selectBox {
  margin: 5px 0;
  position: relative;

  .label {
    align-items: center;
    color: $secondaryFontColor;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    justify-content: space-between;
    margin: 10px 0 5px;

    .infoIcon {
      margin: 0 0 -2px 3px;
      width: 13px;
    }

    .optionalLabel {
      color: #6e7191;
      float: right;
    }
    input {
      border: none!important;
    }
  }

  .adornment {
    position: absolute;
    top: 8px;

    &.withLabel {
      top: 13px;
    }

    &.left {
      left: 8px;
    }

    &.right {
      right: 8px;
    }
  }

  .errorMessage {
    color: #ff471a;
    display: inline-block;
    font-size: 12px;
    height: 17px;
  }

  .inputErrorLabel {
    color: #f44336;
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
    line-height: 25px;
  }
}

.tooltip {
  text-transform: none;
  width: 200px;
}

.filled {
  background: rgba(201, 202, 217, 0.19);
  border-radius: 3px;
  height: 50px;
  background: #fff;
  border-radius: 10px;
}

.bordered {
  border: 1px solid #a0a3bd !important;
  border-radius: 3px;
}
