@import '../../styles/variables.scss';

.feedback {
  width: 100%;
  align-items: stretch;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  .nurtureBoxImg {
    left: 20px;
    position: fixed;
    top: 20px;
  }

  .topSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-weight: 600;
      font-size: 35px;
      line-height: 42px;
      color: #14142a;
      padding-bottom: 10px;
    }

    .description {
      font-weight: normal;
      font-size: 25px;
      color: #14142a;
    }
  }

  .contentSection {
    width: 100%;
    display: flex;
    .leftSection {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 2% 0;
      img {
        @media screen and (max-height: 750px) {
          transform: scale(0.75);
        }
        @media screen and (max-width: 1100px) {
          transform: scale(0.75);
        }
      }
    }

    .inputSection {
      width: 50%;
      display: flex;
      flex-direction: column;
      margin: 2% 0;

      h6 {
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        color: #14142a;
        padding: 3% 0;
      }

      input {
        font-weight: normal;
        font-size: 20px;
        color: #4e4b66;
      }

      label {
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        color: #4e4b66;
      }

      textarea {
        width: 80%;
        height: 110px;
        background: #ffffff;
        border: 1px solid #d9dbe9;
        box-sizing: border-box;
        border-radius: 8px;
        resize: none;
        padding: 2%;
        font-size: 16px;
        line-height: 24px;
        color: #4e4b66;
        @media screen and (min-width: 1500px) {
          width: 60%;
        }
      }
      &.focus-visible {
        outline: 1px solid #d9dbe9;
      }

      .successMsg {
        color: green;
      }
      .btnSection {
        width: 80%;
        display: flex;
        margin-top: 2%;
        justify-content: right;
        @media screen and (min-width: 1500px) {
          width: 60%;
        }

        .clearBtn {
          width: 30%;
          margin-right: 5%;
          background: transparent;
          color: #4e4b66;
          box-shadow: none;
        }

        .saveBtn {
          width: 30%;
        }
      }
    }
  }
}

.feedbackSuccessCntr {
  position: absolute;
  width: 685px;
  height: 488px;
  background: $whiteColor;
  border-radius: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  img {
    height: 315px;
  }
  .msg {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: $primaryFontColor;
    text-align: center;
  }
}
.backdrop {
  background: rgba(131, 129, 129, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}
.options {
  padding: 1% 0;
}
