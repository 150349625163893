.loaderContainer {
  background-color: #ffff;
  border: 1px solid #a0a3bd;
  border-radius: 5px;
  box-shadow: 0 1px 28px rgba(187, 191, 208, 0.28);
  box-sizing: border-box;
  left: 50%;
  padding: 60px 28px 28px 28px;
  position: absolute;
  top: 50%;
  transform: translate(-40%, -50%);
  transition: all 1s, transform 1s;
  width: 300px;
  z-index: 4001;

  .closeButton {
    color: #4e4b66;
    font-size: 21px;
    position: absolute;
    right: 20px;
    top: 16px;
  }

  .bodyContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .progressBar {
      margin: 8px 0 26px;
      width: 155px !important;
    }

    .loaderContent {
      color: #4e4b66;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.spinnerContainer {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-40%, -50%);
  z-index: 4001;

  .progressBar {
    animation: spinner-animation 900ms linear infinite;
    margin: 8px 0 26px;
    width: 155px !important;

    &.medium {
      width: 100px !important;
    }
  }
}

@keyframes spinner-animation {
  100% {
    transform: rotate(360deg);
  }
}
