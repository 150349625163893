@import "../../styles/variables.scss";
@import "../../styles/utils.scss";

.defaultButton {
  align-items: center;
  background-color: $primaryColor;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 2px 7px rgba(128, 151, 177, 0.4);
  color: #ffff;
  cursor: pointer;
  display: flex;
  font-size: 0.812em;
  font-weight: 600;
  height: 34px;
  justify-content: center;
  outline: none;
  padding: 0 15px;
}

.borderedButton {
  background: #ffff;
  border: 1px solid $primaryBorder;
  box-shadow: none;
  color: $primaryFontColor;
}

.buttonDisabled {
  background: #4c5a73 !important;
  cursor: not-allowed;
  opacity: 0.8;
}
