@import '../../styles/variables.scss';
@import '../../styles/utils.scss';

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $whiteColor;

  .linkedInBox {
    align-items: center;
    border-radius: 7px;
    box-sizing: border-box;
    color: $primaryFontColor;
    text-align: center;

    .linkedInboxInner {
      background: #fff;
      padding: 30px 0;
      img {
        height: 550px;
        @media screen and (max-height: 800px) {
          height: 420px;
        }
      }

      .mainText {
        font-size: 30px;
        font-weight: 600;
        padding: 30px 10px 10px 10px;
        color: #14142a;
      }

      .miniText {
        color: #4e4b66;
        font-size: 20px;
        line-height: 26px;
        padding-bottom: 5px;
        padding: 0 12%;
      }

      .linkedInButton {
        align-items: center;
        display: inline-flex;
        justify-content: center;
        margin: 15px;
        .linkedIn {
          background: #2934d0;
          border-radius: 10px;
          box-shadow: 0 4px 10px rgba(44, 90, 246, 0.23);
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          height: 50px;
          line-height: 19px;
          padding: 20px 50px !important;
          text-align: center;
          width: 325px;
          margin: 5% 0;
        }
        .redirection {
          text-align: center;
          color: #4e4b66;
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .linkedInbox {
    margin: 4%;
    .linkedInImage {
      max-width: 100%;
    }
  }
}
