%center-flex-content {
  align-items: center;
  display: flex;
  justify-content: center;
}

%flex-column {
  display: flex;
  flex-direction: column;
}
