@import "../../../styles/variables.scss";

.textField {
  font-size: 0.875em;
  position: relative;

  .label {
    align-items: center;
    color: $secondaryFontColor;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    justify-content: space-between;
    margin: 10px 0 5px;

    .optionalLabel {
      color: #6e7191;
      float: right;
    }
  }

  .adornment {
    position: absolute;
    top: 8px;

    &.withLabel {
      top: 13px;
    }

    &.left {
      left: 8px;
    }

    &.right {
      right: 8px;
    }
  }

  .filledTextInput {
    background: rgba(201, 202, 217, 0.19);
    border-radius: 3px;

    &::placeholder {
      color: $placeholder !important;
      font-size: calculateEm(13px);
    }
  }

  .textInput {
    border: 1px solid $primaryBorder;
    border-radius: 3px;
    box-sizing: border-box;
    height: 34px;
    padding: 0 10px;
    width: 100%;
    box-shadow: none;
    transition: box-shadow 0.1s linear;

    &.endAdornmentPadding {
      padding: 0 0 0 15px;
    }

    &.startAdornmentPadding {
      padding: 0 0 0 30px;
    }

    &.bothAdornmentPadding {
      padding: 0;
    }
  }

  .textInput::placeholder {
    color: $placeholder;
  }

  .textInput:focus {
    box-shadow: 0 0 1pt 1pt #2934d0;
    outline: none !important;
  }

  .errorContainer {
    min-height: 5px;
  }
}
