@import '../../styles/variables.scss';
@import '../../styles/utils.scss';

$boxHeight: 225px;
$boxWidth: 500px;

.googleBtnIcon {
  margin-left: 10px;
  margin-right: 55px;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  &.loginLoading {
    opacity: 0.5;
    pointer-events: none;
  }

  .logoSection {
    margin: 3% 0;
    img {
      height: 30px;
    }
  }

  .loginBox {
    @extend %flex-column;
    align-items: center;
    border-radius: 7px;
    box-sizing: border-box;
    color: $primaryFontColor;
    text-align: center;

    .loginBoxInner {
      background: #fff;
      padding: 30px 0;
      .logInImage {
        height:550px;
        @media screen and (max-height:800px) {
           height: 420px;
        }
      }
      .errorMessage {
        margin: 0;
        font-size: 14px;
        color: $dangerColor;
      }
    }

    .mainText {
      font-size: 30px;
      font-weight: 600;
      padding: 30px 10px 10px 10px;
      color: #14142a;
    }

    .miniText {
      color: #4e4b66;
      font-size: 20px;
      line-height: 26px;
      padding-bottom: 5px;
      padding: 0 12%;
    }

    .logo {
      width: 65px;
    }

    .loginBtn {
      background: $primaryColor;
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(44, 90, 246, 0.23);
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      margin: auto;
      margin-bottom: 3%;
      margin-top: 5%;
      padding: 20px 0;
      text-transform: none;
      width: 325px;
      height: 50px;
      justify-content: flex-start;
    }

    .termsAndConditions {
      p {
        color: $placeholder;
        font-size: 1em;
        line-height: 22px;
        padding: 0 15%;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .loginBox {
    .logo {
      width: 55px !important;
    }
  }
}

@media screen and (max-width: 425px) {
  .logoSection {
    margin: 25px !important;
  }

  .loginBox {
    margin: 4%;
    .logInImage {
      max-width: 100%;
    }
  }
}
